<template>
  <div>
    <div class="title">
      <div class="back" @click="goback">
        <img src="../../assets/zjt.png" alt="" />返回
      </div>
      {{ studyDes.chapterlist[studyDes.index].title }}
    </div>
    <!-- <recordtime type="3" record="1"> </recordtime>
    <showtime type="3"></showtime> -->
    <writetime type="3"></writetime>
    <div class="content11" v-for="(item, index) in list" :key="index">
      <div class="text">{{ item.title }}</div>
      <img :src="item.attr" alt="" class="study-img" />
      <div class="content" v-html="item.content"></div>
    </div>
    <div class="btn">
      <div
        :class="this.studyDes.index == 0 ? 'item aaa' : 'item'"
        @click="next"
      >
        上一节
      </div>
      <div
        :class="
          this.studyDes.index == this.studyDes.chapterlist.length - 1
            ? 'item aaa'
            : 'item'
        "
        @click="pro"
      >
        {{ inx == list.length - 1 ? "完成" : "下一节" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      list: [],
      inx: 0,
      chapter: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      studyDes: (state) => state.studyDes,
    }),
  },
  methods: {
    ...mapActions(["getCharLer", "getClassCharter"]),
    next() {
      if (this.studyDes.index == 0) {
        this.$message({
          message: "已经是第一节了",
          type: "cancel",
        });
        return false;
      }
      this.studyDes.index--;
      this.getData();
    },
    pro() {
      if (this.studyDes.index == this.studyDes.chapterlist.length - 1) {
        this.$router.back();
        return false;
      }
      this.studyDes.index++;
      this.getData();
    },
    goback() {
      this.$router.go(-1);
    },
    getData() {
      const loading = this.$loading({
        lock: true,
        text: "内容加载中请耐心等待",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.getCharLer({
        username: this.userInfo.username,
        id: this.studyDes.chapterlist[this.studyDes.index].id,
        classid: this.classid,
      }).then((res) => {
        if (res.code == 200) {
          window.scrollTo(0, 0);
          loading.close();
          this.list = res.data.list;
        } else {
          loading.close();
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  text-align: center;
  height: 77px;
  line-height: 77px;
  font-size: 16px;
  .back {
    cursor: pointer;
    position: absolute;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      height: 20px;
      vertical-align: middle;
      margin-right: 15px;
    }
  }
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 38px;
  .item {
    cursor: pointer;
    width: 137px;
    text-align: center;
    line-height: 41px;
    margin-right: 24px;
    height: 41px;
    background: #e9f3ff;
    border: 2px solid #137cfb;
  }
  .aaa {
    background: #f7f7f7;
    border: 2px solid #ccc;
  }
}
.text {
  margin: 20px 0 30px 0;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: #000;
}
.study-img {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
.content11 {
  .content {
    font-size: 18px;
  }
  img {
    width: 600px;
  }
}
</style>
